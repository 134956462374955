.do-color-picker{

}

.do-color-picker .react-colorful {
    width: auto;
    border-radius: 0;
}

.do-color-picker .react-colorful__saturation{
    border-radius: 0;
}

.do-color-picker .react-colorful__last-control{
    border-radius: 0;
}

.resposido-color-pickerve .react-colorful__hue-pointer,
.do-color-picker .react-colorful__alpha-pointer {
  width: 10px;
  border-radius: 3px;
}

.do-color-picker .react-colorful__saturation-pointer {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}

  