// Custom.scss
// Option B: Include parts of CoreUI

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
//@import "node_modules/@coreui/coreui/scss/functions";

// 2. Include any default variable overrides here
$body-bg: #FBFBFB;
$body-color: #111719;
$primary: #FE724C;
$success: #6AE494;
$input-border-color: #858992;
$input-border-radius: 1rem;
$input-padding-y: 0.8rem;
$input-padding-x: 1rem;
$input-color: #858992;
$input-font-weight: 300;
$btn-color:$body-bg;
//label
$form-label-color: #323232;
$form-label-font-weight: 400;
.form-label{
    margin: 0 0 1rem 0;
}

.form-item{
margin: 1rem 0;
}

.form-control-focus{    
    width: 100% !important;
    height: 100% !important;
    border: #000 solid 1px !important;
    border-radius: 0 !important;        
}



// 3. Include remainder of required CoreUI stylesheets
//@import "node_modules/@coreui/coreui/scss/variables";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
// @import "node_modules/@coreui/coreui/scss/maps";
// @import "node_modules/@coreui/coreui/scss/mixins";
// @import "node_modules/@coreui/coreui/scss/root";

// 6. Optionally include any other parts as needed
// @import "node_modules/@coreui/coreui/scss/utilities";
// @import "node_modules/@coreui/coreui/scss/reboot";
// @import "node_modules/@coreui/coreui/scss/type";
// @import "node_modules/@coreui/coreui/scss/images";
// @import "node_modules/@coreui/coreui/scss/containers";
// @import "node_modules/@coreui/coreui/scss/grid";
// @import "node_modules/@coreui/coreui/scss/helpers";

// // 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
// @import "node_modules/@coreui/coreui/scss/utilities/api";

// 8. Add additional custom code here

@import "node_modules/@coreui/coreui/scss/coreui";

.btn{
    box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3);
    color: #111719;
}


.btn-primary{
    background-color: #FE724C;
    color: $body-bg;
}
.btn-danger{
    background-color: #F44949;
    color: $body-bg
}
.btn-light, .btn-link{
    background-color: #FFFFFF;
    color: $body-color;    
}
.btn-link{
    color: #858992;
}


//Form Check
.form-check {
    display: flex;
    padding: 0;
    .form-check-input {
        float: none;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }
    label {
        flex: 1
    }
}