html,
body {
    min-height: 100%;
    height: 100%;
    font-family: 'Rubik', sans-serif !important;    
}

#app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex: 1
}

.flex-item {
    display: flex;
}

.flext-item__justify-center {
    justify-items: center;
}

.flext-item__justify-start {
    justify-items: flex-start
}

.flext-item__justify-end {
    justify-items: flex-end;
}

.flext-item__justify-baseline {
    justify-items: baseline
}

.flex-item__align-center {
    align-items: center;
}

.flex-item__align-start {
    align-items: flex-start;
}

.flex-item__align-center {
    align-items: flex-end;
}

.flex-item__align-baseline {
    align-items: baseline;
}

.tooltip .tooltip-inner {
    background-color: gold;
    color: #000;
    padding: 6px 12px;
    font-size: 16px;
}

.tooltip .tooltip-arrow::before {
    border-top-color: gold !important;
}


.container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.container>p {
    font-size: 1rem;
}

.container>em {
    font-size: .8rem;
}


.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}

h4.files-title{
    font-size: 14px;
}

.DraftEditor-root{
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;    
}

.public-DraftEditor-content{
    min-height: 100px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .progress{
    width: 100%
  }